import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Years',
  slug: '/years/',
  title: 'Years',
}

export const query = graphql`
  query YearsPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "years.jpg" }) { ...ImageMain }
    results: postgres {
      years: allYearsList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: ID_ASC,
      ) {
        ...Year
      }
    }
  }
`

export default ({ data }) => {
  const { years } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Years'
  const title = `${entity.singular} ${page}`
  const description = `
    Fragrance changes over time, following the flows of culture, history,
    art, and important events. Preferences for certain Notes and Accords
    shift slowly as surrounding styles change. Old fads reappear like new,
    modern synthetic notes are birthed, and new combinations are explored.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.years,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    things: years,
    slug: links.years,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="years.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {years.map(year => (
          <ListItem key={year.id}>
            <Link href={year.slug}>
              {year.id}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
